/* styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.navbar-links a {
  text-decoration: none;
  color: #1e3932;
  font-weight: bold;
}

.navbar-links a:hover {
  color: #abb2bb;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

/* @font-face {
    font-family: 'Poppins';
    src: url('/path-to-your-fonts/poppins.woff2') format('woff2'),
         url('/path-to-your-fonts/poppins.woff') format('woff');
    font-display: fallback; 
  } */

@media (max-width: 480px) {
  section {
    /* margin: 0 10px 0 10px; */
  }

  p {
    /* font-size: 14px; */
  }

  h1 {
    /* font-size: 14px; */
  }

  h2 {
    /* font-size: 13px; */
  }

  #__next>div>main>div>main {
    /* margin-top: 81px; */
  }
}

@media (max-width: 801px) {
  section {
    /* margin: 0 30px 0 30px; */
  }
}

@layer utilities {
  .scrollbar-thin::-webkit-scrollbar {
    width: 4px;
    opacity: 0;
  }

  .scrollbar-thin::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .scrollbar-thin::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);

    border-radius: 10px;
  }

  .scrollbar-thin::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  .scrollbar-thin {
    scrollbar-width: thin;
    scrollbar-color: #888 #fff;
  }

  .scrollbar-thin:hover::-webkit-scrollbar-thumb {
    opacity: 1;
  }

  .clip-path-custom {
    clip-path: polygon(0 0, 100% 50%, 0 100%);
  }
}

@layer utilities {
  .scrollbar-thin::-webkit-scrollbar {
    width: 4px;
    opacity: 0;
  }

  .scrollbar-thin::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .scrollbar-thin::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);

    border-radius: 10px;
  }

  .scrollbar-thin::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  .scrollbar-thin {
    scrollbar-width: thin;
    scrollbar-color: #888 #fff;
  }

  .scrollbar-thin:hover::-webkit-scrollbar-thumb {
    opacity: 1;
  }
}

.clip-path-custom {
  clip-path: polygon(0 0, 100% 50%, 0 100%);
}

.custom-hide {
  display: none !important;
}
